import { Box, Button, Container, CssBaseline, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from './Root/theme';

export default function ErrorBoundary() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Container maxWidth="sm">
          <Typography variant="h3">Ops! Da ist etwas schief gelaufen.</Typography>
          <Typography variant="h6">
            Versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an unseren
            Kundendienst.
          </Typography>

          <Button
            onClick={() => window.location.reload()}
            sx={{ mt: 4, textTransform: 'capitalize' }}
          >
            <Typography variant="h6">Wiederholen &gt;</Typography>
          </Button>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
